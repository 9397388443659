import { Avatar } from '@mui/material';
import canada from '@op/shared/assets/images/canada.png';
import denmark from '@op/shared/assets/images/denmark.png';
import finland from '@op/shared/assets/images/finland.png';
import sweden from '@op/shared/assets/images/sweden.png';
import Us from '@op/shared/assets/images/us.png';
import { ExchangeCode } from '@op/shared/src/models/enums/enums';
import { customizationState } from '@op/shared/src/states';
import React from 'react';
import { useRecoilValue } from 'recoil';

interface FlagWidgetProps {
  exchange: string | undefined;
  variant: 'rounded' | 'square';
  width?: number;
  height?: number;
}

export const FlagWidget: React.FC<FlagWidgetProps> = ({ exchange, variant, width, height }: FlagWidgetProps) => {
  const styleObj = { width: width || 20, height: height || 15 };
  const customization = useRecoilValue(customizationState);

  if (!exchange || exchange.trim() === '') {
    return null;
  }

  if (!customization.showCurrencyOrExchangeFlags) {
    return null;
  }

  const getSourceImage = (value: string) => {
    switch (value) {
      case ExchangeCode.US:
        return <Avatar alt="flag" variant={variant} src={Us} style={styleObj} />;
      case ExchangeCode.XSTO: // Stockholm
      case ExchangeCode.INDXSTO:
        return <Avatar alt="flag" variant={variant} src={sweden} style={styleObj} />;
      case ExchangeCode.XCSE:
      case ExchangeCode.INDXCSE: // Copenhegan
        return <Avatar alt="flag" variant={variant} src={denmark} style={styleObj} />;
      case ExchangeCode.XHEL:
      case ExchangeCode.INDXHEL: // Helsinki
        return <Avatar alt="flag" variant={variant} src={finland} style={styleObj} />;
      case ExchangeCode.XTSE:
        return <Avatar alt="flag" variant={variant} src={canada} style={styleObj} />;
      default:
        return <Avatar alt="flag" variant={variant} src={Us} style={styleObj} />;
    }
  };

  return getSourceImage(exchange);
};
