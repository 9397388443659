import { Checkbox, Grid, useTheme } from '@mui/material';
import { IFilter2, cloneDeep } from '@op/shared/src/models';
import { IFilterGroup } from '@op/shared/src/models/what/i-filter-group';
import React, { useEffect, useState } from 'react';
import LocalizationContext from '../../react-i18next/localization-context';
import { OPBoldTypograpghy, OPToggleButton } from '../../styled';
import { StyledCheckBox } from '../../tradestation/ts-styles-component';

export interface Props {
  filter: IFilterGroup;
  onClick: (group: IFilterGroup) => void;
}

export const PremiumFilterWidget: React.FC<Props> = ({ filter, onClick }: Props) => {
  const theme = useTheme();
  const { t } = React.useContext(LocalizationContext);
  const [selecteAllPremium, setSelectAllPremium] = useState(false);

  useEffect(() => {
    const allSelected = filter.filters.every((p) => p.selected);
    setSelectAllPremium(allSelected);
  }, [filter]);

  const onSelect = (f: IFilter2) => {
    const clone = cloneDeep(filter);
    for (const x of clone.filters) {
      if (x.name !== f.name) {
        continue;
      }
      x.selected = !f.selected;
    }
    onClick(clone);
  };

  const onReset = (selectAll: boolean) => {
    const clone = cloneDeep(filter);
    for (const x of clone.filters) {
      x.selected = selectAll;
    }
    onClick(clone);
  };

  const handleSwitchToggle = (event) => {
    const isSelected = event.target.checked;
    setSelectAllPremium(isSelected);
    onReset(isSelected);
  };

  return (
    <Grid item xs={12} container justifyContent="space-between">
      <Grid item xs={12}>
        <OPBoldTypograpghy>{t(filter.translationKey)}</OPBoldTypograpghy>
      </Grid>
      <Grid item xs={12} container justifyContent="space-between">
        <Grid item xs={10.5} sx={{ border: `1.5px solid ${theme.palette.primary.light}`, borderRadius: '5px' }}>
          <Grid item p={0.5} sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {filter.filters.map((f) => {
              return (
                <OPToggleButton key={f.name} value={f.value} selected={f.selected} onClick={() => onSelect(f)}>
                  <OPBoldTypograpghy>{t(f.translationKey)}</OPBoldTypograpghy>
                </OPToggleButton>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={'auto'} sx={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            checked={selecteAllPremium}
            onClick={handleSwitchToggle}
            icon={<StyledCheckBox />}
            checkedIcon={<StyledCheckBox sx={{ backgroundColor: theme.palette.primary.main }} />}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
