import HomeIcon from '@mui/icons-material/Home';
import { Button, Grid, Paper, Tab, Tabs, useTheme } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { Permissions } from '@op/shared/src/models/enums/enums';
import {
  accountState,
  configurationState,
  customizationState,
  isSubscriptionRenewedState,
  previousLocationState,
} from '@op/shared/src/states';
import React, { Suspense, useContext, useEffect, useState } from 'react';
import { Link, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  AccountSetting,
  ChangeEmail,
  ChangePassword,
  Commissions,
  PortfolioTabWidget,
  ProfileTabsWidget,
  SharedTrade,
} from '.';
import LocalizationContext from '../react-i18next/localization-context';
import { useOPNavigate } from '../states/use-navigate-search';
import { SubscriptionWidget } from '../subscription';
import { BillingHistory } from '../subscription_v1';
import { DeleteAccountWidget } from './delete-account-widget';
import { PortfolioAlert } from './portfolio-tab-alert';
import { PrivacyPolicy } from './privacy-policy';
import { UserSettingsWidget } from './user-settings-widget';

const FallbackSkeleton: React.FC = () => {
  return (
    <>
      <Grid sx={{ pt: 0 }}>
        <Skeleton animation="wave" variant="text" height={400} width="90%" />
      </Grid>
    </>
  );
};

const AccountTabsWidget: React.FC = () => {
  const configuration = useRecoilValue(configurationState);
  const customization = useRecoilValue(customizationState);
  const prevLocation = useRecoilValue(previousLocationState);
  const [account] = useRecoilState(accountState);
  const isSubscriptionRenewed = useRecoilValue(isSubscriptionRenewedState);
  const { t } = useContext(LocalizationContext);
  const [value, setValue] = useState(0);
  const [selectedTabIndex, setselectedTabIndex] = useState(0);
  const theme = useTheme();
  const navigate = useOPNavigate();
  const location = useLocation();

  const routes = [
    '',
    'account-settings',
    'subscriptions',
    'portfolio',
    'portfolio-alerts',
    'billing-history',
    'share-trades',
    'privacy',
    'security',
    'change-email',
    'commissions',
    'income-settings',
    'privacy-policy',
  ];

  const titles = [
    'Profile',
    'AccountSetting',
    'Subscriptions',
    'Portfolio',
    'PortfolioAlertDelivery',
    'BillingHistory',
    'SharedTrades',
    'Privacy',
    'Security',
    'ChangeEmailAddress',
    'IncomeSettings',
    'PrivacyPolicy',
  ];

  useEffect(() => {
    const routeIndex = routes.map((r) => `/profile/${r}`).findIndex((f) => f === location.pathname);
    if (routeIndex && routeIndex > 0) {
      setValue(routeIndex);
      setselectedTabIndex(routeIndex);
      return;
    }
    setValue(0);
    setselectedTabIndex(0);
  }, [location]);

  useEffect(() => {
    document.title = `${titles[value]} | OptionsPlay` || ` OptionsPlay for the Rest of Us | Welcome to OptionsPlay `;
  }, [value]);

  if (!account || !account.securityModel || !configuration) {
    return <></>;
  }

  const isAdmin = account.isAdmin;
  const hasManualPortfolioUpoadAccess = account.hasManualPortfolioUpoadAccess;
  const isViewOPPEnabled = account.isViewOPPEnabled;
  // const subscriptionStatus = account.securityModel.subscriptionStatus;
  const manageProfilePermission = account.securityModel.hasPermission(Permissions.MANAGE_PROFILE);
  const profileSubscriptionPermission = account.securityModel.hasPermission(Permissions.MANAGE_PROFILE_SUBSCRIPTIONS);
  const portfolioAlertPermission = account.securityModel.hasPermission(Permissions.MANAGE_PORTFOLIO_EMAIL_DELIVERY);
  const sharedTradesPermission = account.securityModel.hasPermission(Permissions.ALLOW_SHARING);
  const changePasswordPermission = account.securityModel.hasPermission(Permissions.ALLOW_CHANGE_PASSWORD);
  const commissionPermission = account.securityModel.hasPermission(Permissions.MANAGE_COMMISSIONS);
  const icomeSettingsPermission = account.securityModel.hasPermission(Permissions.MANAGE_COVERED_CALL_SETTINGS);
  const portfolioPermission = account.securityModel.hasPermission(Permissions.VIEW_POWERHOUSE_O_P_P);
  const individualSubscriptionEnabled = configuration.individualSubscriptionEnabled;

  const showHideSubsription =
    !profileSubscriptionPermission || !individualSubscriptionEnabled || !customization.showSubscription;

  const accountTabs = [
    {
      id: 1,
      tabname: 'profile.menu.titles.profile',
      route: '',
      hide: !manageProfilePermission,
      children: <ProfileTabsWidget />,
    },
    {
      id: 2,
      tabname: 'profile.menu.titles.accountSettings',
      route: routes[1],
      hide: !manageProfilePermission,
      children: <AccountSetting />,
    },
    {
      id: 3,
      tabname: 'profile.menu.titles.subscriptions',
      route: routes[2],
      hide: showHideSubsription,
      children: <SubscriptionWidget />,
    },
    {
      id: 4,
      tabname: 'profile.menu.titles.portfolio',
      route: routes[3],
      hide: !isViewOPPEnabled || !portfolioPermission, //removing portfolio fon non viewOPP user
      children: <PortfolioTabWidget isAdmin={isAdmin} hasManualPortfolioUpoadAccess={hasManualPortfolioUpoadAccess} />,
    },
    {
      id: 5,
      tabname: 'profile.menu.titles.portfolioAlertsDelivery',
      route: routes[4],
      hide: !isViewOPPEnabled || !portfolioAlertPermission, //removing portfolio fon non viewOPP user
      children: <PortfolioAlert />,
    },
    {
      id: 6,
      tabname: 'profile.menu.titles.billingHistory',
      route: routes[5],
      hide: true,
      children: <BillingHistory />,
    },
    {
      id: 7,
      tabname: 'profile.menu.titles.sharedTrades',
      route: routes[6],
      hide: !sharedTradesPermission,
      children: <SharedTrade />,
    },
    {
      id: 8,
      tabname: 'profile.menu.titles.privacy',
      route: routes[7],
      hide: false,
      children: <DeleteAccountWidget />,
    },
    {
      id: 9,
      tabname: 'profile.menu.titles.security',
      route: routes[8],
      hide: !changePasswordPermission,
      children: <ChangePassword />,
    },
    {
      id: 10,
      tabname: 'profile.menu.titles.changeEmailAddress',
      route: routes[9],
      hide: !manageProfilePermission,
      children: <ChangeEmail />,
    },
    {
      id: 11,
      tabname: 'profile.menu.titles.commissions',
      route: routes[10],
      hide: !isAdmin || !commissionPermission, //removing commissions for non admin and if Manage Commission permission is not there.
      children: <Commissions />,
    },
    {
      id: 12,
      tabname: 'profile.menu.titles.incomeSettings',
      route: routes[11],
      hide: false,
      children: <UserSettingsWidget showIncomeSettings={icomeSettingsPermission} />,
    },
    {
      id: 13,
      tabname: 'profile.menu.titles.privacyPolicy',
      route: routes[12],
      hide: true,
      children: <PrivacyPolicy />,
    },
  ];

  let listTabs = [...accountTabs].filter((t) => !t.hide);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number): void => {
    const newindexOfSelectedTab = accountTabs.find((v) => v.id === listTabs[newValue].id)?.id || 0;
    setValue(newindexOfSelectedTab - 1);
    setselectedTabIndex(newindexOfSelectedTab - 1);
  };

  const navigatebackToApp = () => {
    if (isSubscriptionRenewed) {
      window.location.href = window.location.origin;
      return;
    }
    if (prevLocation && prevLocation.includes('profile')) {
      navigate('/');
      return;
    }
    navigate(`${prevLocation || '/'}`);
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid container xs={12} sm={10} md={12} xl={12} spacing={2} justifyContent="center">
        <Grid item xs={8} sm={3} md={3}>
          <Paper>
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              orientation="vertical"
              value={listTabs.findIndex((v) => v.id === selectedTabIndex + 1)}
              onChange={handleChange}>
              {listTabs.map((tab) => (
                <Tab
                  sx={{
                    p: '12px 16px',
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    alignItems: 'flex-end',
                  }}
                  key={tab.id}
                  label={t(tab.tabname)}
                  component={Link}
                  to={tab.route}
                />
              ))}
            </Tabs>
          </Paper>
          {!account.isValidSubscription ? undefined : (
            <Button
              onClick={() => navigatebackToApp()}
              sx={{
                width: '100%',
                alignItems: 'flex-end',
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
                '& .MuiButton-iconSizeMedium > *:first-child': {
                  marginBottom: '3px',
                },
              }}
              variant="outlined"
              startIcon={<HomeIcon />}>
              {t('profile.menu.titles.backToTheApp')}
            </Button>
          )}
        </Grid>

        <Grid item md={8} xs={12} sm={8}>
          <Suspense fallback={<FallbackSkeleton />}>
            <Routes>
              {listTabs.map((tab) => {
                return <Route key={tab.id} path={tab.route} element={tab.children} />;
              })}
              <Route path="*" element={<Navigate to="/profile" replace />} />
            </Routes>
          </Suspense>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AccountTabsWidget;
