import { Checkbox, Grid, useTheme } from '@mui/material';
import { IFilter2, cloneDeep } from '@op/shared/src/models';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { IFilterGroup } from '@op/shared/src/models/what/i-filter-group';
import { selectedSymbolState } from '@op/shared/src/states';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import LocalizationContext from '../react-i18next/localization-context';
import { OPBoldTypograpghy, OPLightTypograpghy, OPToggleButton } from '../styled';
import { StyledCheckBox } from '../tradestation/ts-styles-component';

export interface Props {
  filter: IFilterGroup;
  onClick: (group: IFilterGroup) => void;
  isTechnicalIdea?: boolean;
}

export const LiquidityRatingFilterWidget2: React.FC<Props> = ({ filter, onClick, isTechnicalIdea }: Props) => {
  const theme = useTheme();
  const { t } = React.useContext(LocalizationContext);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const [selectedAllLiquidity, setSelectedAllLiquidity] = useState(false);

  useEffect(() => {
    const allSelected = filter.filters.every((l) => l.selected);
    setSelectedAllLiquidity(allSelected);
  }, [filter]);

  const onSelect = (f: IFilter2) => {
    const clone = cloneDeep(filter);
    for (const x of clone.filters) {
      if (x.name !== f.name) {
        continue;
      }
      x.selected = !f.selected;
    }
    onClick(clone);
    logActivity(f);
  };

  const logActivity = (filter: IFilter2) => {
    ApplicationContext.userActivityHub?.logActivity(
      'liquidityFilter',
      'liquidityFilter',
      filter.value === 1 ? 'notLiquid' : filter.value === 2 ? 'someWhatLiquid' : 'veryLiquid',
      'click',
      `${selectedSymbol}`,
    );
  };

  const onReset = (selectAll: boolean) => {
    const clone = cloneDeep(filter);
    clone.filters = clone.filters.map((x) => ({
      ...x,
      selected: selectAll,
    }));
    onClick(clone);
  };

  const handleCheckToggle = (event) => {
    const isSelected = event.target.checked;
    setSelectedAllLiquidity(isSelected);
    onReset(isSelected);
  };

  return (
    <Grid item xs={12} container justifyContent="space-between">
      <Grid item xs={12}>
        <OPBoldTypograpghy>{t(filter.translationKey)}</OPBoldTypograpghy>
      </Grid>
      <Grid item xs={12} container justifyContent="space-between">
        <Grid
          item
          xs={10.5}
          sx={{
            border: `1.5px solid ${theme.palette.secondary.dark}`,
            borderRadius: '5px',
            backgroundColor: theme.palette.selectAndTextField.light,
          }}>
          <Grid item p={0.5} sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {filter.filters.map((f) => {
              return (
                <OPToggleButton key={f.name} value={f.value} selected={f.selected} onClick={() => onSelect(f)}>
                  <OPLightTypograpghy>{t(f.translationKey)}</OPLightTypograpghy>
                </OPToggleButton>
              );
            })}
          </Grid>
        </Grid>
        <Grid xs={'auto'} sx={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            checked={selectedAllLiquidity}
            onClick={handleCheckToggle}
            icon={<StyledCheckBox />}
            checkedIcon={<StyledCheckBox sx={{ backgroundColor: theme.palette.primary.main }} />}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
