import { Checkbox, Grid, useTheme } from '@mui/material';
import { cloneDeep } from '@op/shared/src/models';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { scanFilterState, selectedSymbolState } from '@op/shared/src/states';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import LocalizationContext from '../../react-i18next/localization-context';
import { GuideItem } from '../../side-menu';
import { OPBoldTypograpghy, OPLightTypograpghy, OPToggleButton } from '../../styled';
import { StyledCheckBox } from '../../tradestation/ts-styles-component';

export const ScansFilterWidget2: React.FC = () => {
  const theme = useTheme();
  const { t } = React.useContext(LocalizationContext);
  const [scanFilter, setScanFilter] = useRecoilState(scanFilterState);
  const selectedSymbol = useRecoilValue(selectedSymbolState);
  const [selectAllScans, setSelectAllScans] = useState(false);

  useEffect(() => {
    if (!scanFilter) {
      return;
    }
    const allSelected = scanFilter.filters.every((s) => s.selected);
    setSelectAllScans(allSelected);
  }, [scanFilter]);

  if (!scanFilter) {
    return <></>;
  }

  const onScanChange = (filterValue: string, isSelected: boolean) => {
    const clone = cloneDeep(scanFilter);
    for (const f of clone.filters) {
      if (f.name.includes(filterValue)) {
        f.selected = isSelected;
      }
    }
    setScanFilter(clone);
    logActivity(`${filterValue} ${isSelected ? 'selected' : 'deselected'}`);
  };

  const logActivity = (scanValue: string) => {
    ApplicationContext.userActivityHub?.logActivity(
      'reasonFilter',
      'reasonFilter',
      scanValue,
      'click',
      `${selectedSymbol}`,
    );
  };

  const getFilteredScans = () => {
    const trendFollowing = scanFilter.filters.filter((f) => f.name.toLowerCase().includes('trend following'));
    const cciTrend = scanFilter.filters.filter((f) => f.name.toLowerCase().includes('cci'));
    const counterTrend = scanFilter.filters.filter((f) => f.name.toLowerCase().includes('counter trend'));
    return {
      trendFollowing,
      cciTrend,
      counterTrend,
    };
  };

  const toggleAllSelection = (select: boolean) => {
    const clone = cloneDeep(scanFilter);
    for (const x of clone.filters) {
      x.selected = select;
    }
    setScanFilter(clone);
    setSelectAllScans(select);
  };

  const { trendFollowing, cciTrend, counterTrend } = getFilteredScans();

  const renderToggleButton = (label: string, filterValue: string, filters: any[]) => {
    const isSelected = filters.some((f) => f.selected);
    return (
      <OPToggleButton value={filterValue} selected={isSelected} onClick={() => onScanChange(filterValue, !isSelected)}>
        <OPLightTypograpghy>{label}</OPLightTypograpghy>
      </OPToggleButton>
    );
  };

  const handleSwitchToggle = (event) => {
    const isChecked = event.target.checked;
    toggleAllSelection(isChecked);
  };

  return (
    <Grid item xs={12} container justifyContent="space-between">
      <GuideItem selector=".reasonsDropdown_helpPinPlaceholder" />
      <Grid item xs={12}>
        <OPBoldTypograpghy>{t(scanFilter.translationKey)}</OPBoldTypograpghy>
      </Grid>
      <Grid item xs={12} container justifyContent="space-between">
        <Grid
          item
          container
          xs={10.5}
          sx={{
            border: `1.5px solid ${theme.palette.secondary.dark}`,
            borderRadius: '5px',
            backgroundColor: theme.palette.selectAndTextField.light,
          }}>
          <Grid item container xs={12} p={0.5} sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <Grid item>{renderToggleButton('Trend Following', 'trend following', trendFollowing)}</Grid>
            <Grid item>{renderToggleButton('CCI Trend', 'cci', cciTrend)}</Grid>
            <Grid item>{renderToggleButton('Counter Trend', 'counter trend', counterTrend)}</Grid>
          </Grid>
        </Grid>
        <Grid item xs={'auto'} sx={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            checked={selectAllScans}
            onClick={handleSwitchToggle}
            icon={<StyledCheckBox />}
            checkedIcon={<StyledCheckBox sx={{ backgroundColor: theme.palette.primary.main }} />}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
