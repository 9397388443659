import {
  Account,
  Globalization,
  IGlobalization,
  LanguageType,
  TokenResponse,
  getThemeType,
} from '@op/shared/src/models';
import { EmbedderEventType, ImplementationType } from '@op/shared/src/models/enums/enums';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import {
  embedderLogin,
  fetchSharedTradeById,
  getWidgetLoaderTokens,
  shareRequestPattern,
} from '@op/shared/src/services';
import {
  accountState,
  embedderSentimentAtom,
  flippedState,
  globalizationState,
  isDataLoadingState,
  strategyShareDataState,
  subViewState,
  themeState,
  useOnSentimentChange,
  viewState,
} from '@op/shared/src/states';
import { configMinWidth, configurationState, useFetchAndSetConfiguration } from '@op/shared/src/states/configuration';
import { notificationsState } from '@op/shared/src/states/notification-states';
import React, { useEffect, useRef, useState } from 'react';
import { useMatch, useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { TechnicalIdeas } from '../ideas/technical-idea/technical-ideas';
import LocalizationContext from '../react-i18next/localization-context';
import { AppContainer, PageContainerWidget } from '../routes';
import { ReportsContainer } from '../routes/reports-container';
import { PageLoaderWidget } from '../routes/skeleton-page-widget';
import { useFetchAndSetData } from '../states/use-fetch-and-set-data';
import { ProStrategiesWidget } from './pro-strategies-widget';

export const EmbedderPageWidget: React.FC = () => {
  const fetchAndSetConfiguration = useFetchAndSetConfiguration();
  const [searchParams] = useSearchParams();
  const embedderRoute = useMatch('/embedded/:widgetName');
  const configuration = useRecoilValue(configurationState);
  const fetchAndSetData = useFetchAndSetData();
  const onSentimentChange = useOnSentimentChange();
  const setActiveTheme = useSetRecoilState(themeState);
  const setNotifications = useSetRecoilState(notificationsState);
  const [account, setAccount] = useRecoilState(accountState);
  const setIsDataLoading = useSetRecoilState(isDataLoadingState);
  const setConfigMinWidth = useSetRecoilState(configMinWidth);
  const setIsFlipped = useSetRecoilState(flippedState);
  const setGlobalization = useSetRecoilState(globalizationState);
  const { i18n } = React.useContext(LocalizationContext);
  const setStrategyShareData = useSetRecoilState(strategyShareDataState);
  const setView = useSetRecoilState(viewState);
  const setSubView = useSetRecoilState(subViewState);
  const setEmbedderSentiment = useSetRecoilState(embedderSentimentAtom);
  const [widgetName, setWidgetName] = useState<string | undefined>();
  const [sentiment, setSentiment] = useState<string | undefined>();
  const [isReady, setIsReady] = useState(false);

  const configRef = useRef(false);

  // const [maxWidth, setMaxWidth] = useState('100%');
  //NOTE: Its not useState because, configuration is pulled and state will not have chance to update local state.
  let allowedSecurities: string | undefined;
  let hostToValidateEventsFrom: string | undefined;
  const payloadWigetName = searchParams.get('widgetName') || '';
  const theme = searchParams.get('theme') || '';
  const apiKey = searchParams.get('apiKey');
  const authToken = searchParams.get('authToken') || '';
  const hostOrigin = searchParams.get('hostOrigin');
  const userId = searchParams.get('userId') || '';
  const locale = searchParams.get('locale') || 'en-US';
  const width = searchParams.get('width');
  searchParams.delete('apiKey');
  searchParams.delete('authToken');
  searchParams.delete('hostOrigin');
  searchParams.delete('theme');
  searchParams.delete('height');
  searchParams.delete('width');
  searchParams.delete('destinationId');
  searchParams.delete('locale');
  useEffect(() => {
    document.body.classList.remove(`optionsplay`);
    if (!embedderRoute || !embedderRoute.params) {
      throw new Error('Not embedder route');
    }
    if (configRef.current) {
      return;
    }
    (async () => {
      configRef.current = true;
      const tokenResponse = await requestValidationToken();
      initialize(tokenResponse);
      registerCrossWindowListener();
    })();
  }, []);

  useEffect(() => {
    if (!account) {
      setIsReady(false);
      return;
    }
    if (!configuration || !configuration.isEmbeddingPlatform) {
      setIsReady(false);
      return;
    }
    (async () => {
      // if (payloadWigetName === 'pro') {
      //   const fetchedTrades = await fetchTradeIdeas(false);
      //   if (!fetchedTrades) {
      //     // setNotifications([{ type: 'error', content: 'Unable to get technical ideas.' }]);
      //     return;
      //   }
      //   const sectorFilterGroup = prepareSectors(fetchedTrades.sectors);
      //   const scanFilterGroup = prepareScans(fetchedTrades.scans);
      //   setSectorFilter(sectorFilterGroup);
      //   setScanFilter(scanFilterGroup);
      //   setTrades(fetchedTrades);
      // }
      //TODO: processShareId() is making `fetchAndSetData` api calls, that should be fixed.
      const shouldProcessShare = await processShareId();
      // if (!shouldProcessShare) {
      //   await fetchAndSetData(selectedSymbol, undefined, undefined, undefined, allowedSecurities);
      // }
      // if (
      //   configuration.additionalData &&
      //   configuration.additionalData.allowedSecurities &&
      //   configuration.additionalData.allowedSecurities.trim() !== ''
      // ) {
      //   window.localStorage.setItem('embedder-allowedSecurities', configuration.additionalData.allowedSecurities);
      // }
      setWidgetName(configuration.additionalData?.widgetName || payloadWigetName);
      setIsFlipped(configuration.implementation === ImplementationType.MERRILL.toString());
      setIsReady(true);
    })();
  }, [account, configuration]);

  useEffect(() => {
    if (!sentiment) {
      return;
    }
    onSentimentChange(sentiment);
  }, [sentiment]);

  //TODO: Verify host and other params
  const requestValidationToken = async () => {
    /**
     * Sample response:
     * {
     *  "hostToValidateEventsFrom","Value":"https://bromley-api.optionsplay.com",
     *  "host","Value":"https://bromley-api.optionsplay.com/",
     *  "requestValidationToken","Value":"s2u92+VqVQIoNrozc5UaGtN5nmexHLx0svIDEohyYng+dutnaKT9ToMfSdqqmzSKIap/0v6nB6E4fTtmYAw5dNQOTvMnW1O7vUslC2b7fD+Bm1Z3PpyNVMSxU0+OnaBvXzjqa/NlQ36nfFMbJ9dlE+XoB1SQX89sityCnNR51Jn1JzzEAIUQWGRm4fVVxTmqtvBn034+9tTYK3xKdyMFhcP1BJyqEBuqeSiE951465qCGUeZY2RnXtPGjpX2T9lSzeNN3MWzxmnsb9bHgxDFrA="}
     * }
     */
    //TODO: no need of hostOrigin now. use hostToValidateEventsFrom for event reading.
    const embedderDomain = document.referrer;
    const widgetLoaderTokens = await getWidgetLoaderTokens(embedderDomain);
    hostToValidateEventsFrom = widgetLoaderTokens.hostToValidateEventsFrom;
    const token = widgetLoaderTokens.requestValidationToken;
    const response = await login(token);
    return response;
  };

  const login = async (requestValidationToken: string) => {
    /**
     * if only apikey - pass
     * if only authoken - pass
     * both is there -pass
     * both is not there - fail
     */
    if (!apiKey && !authToken) {
      setNotifications([{ type: 'error', content: 'Cannot load widget without apiKey or authToken specified' }]);
      return;
    }
    if (apiKey) {
      searchParams.set('client_id', apiKey);
    }
    if (authToken && authToken !== '') {
      searchParams.set('auth_token', authToken);
    }
    searchParams.set('grant_type', 'widget');
    searchParams.set('requestValidationToken', requestValidationToken);
    searchParams.set('embedderDomain', document.referrer);
    const response = await embedderLogin(searchParams.toString());
    if (response.hasErrors || response.data === null || response.data === undefined) {
      setNotifications([{ type: 'error', content: response.errors[0].message }]);
      return;
    }
    //TODO: Remove embedRoute check in subscripbiont useEffect to take its effect back.
    return response.data;
  };

  const initialize = async (tokenResponse: TokenResponse | undefined) => {
    if (!tokenResponse) {
      throw new Error('tokenResponse is undefined');
    }
    //TODO: this should be set very first for api to take token in effect.
    ApplicationContext.accessToken = tokenResponse.access_token;
    ApplicationContext.refreshToken = tokenResponse.refresh_token;
    setConfigMinWidth(width);
    const configuration = await fetchAndSetConfiguration();
    if (!configuration) {
      throw new Error('Configuration is undefined');
    }
    allowedSecurities = configuration.additionalData?.allowedSecurities;
    const accountData = Account.fromTokenResponse(tokenResponse, payloadWigetName, userId);
    const selectedTheme = getThemeType(theme);
    let languages: IGlobalization[] = [
      { id: 1033, name: LanguageType.English, fullName: 'English (US)' },
      { id: 2052, name: LanguageType.Chinese, fullName: 'Chinese (China)' },
    ];
    if (configuration.implementation === ImplementationType.BNC) {
      languages = [
        { id: 1033, name: LanguageType.English, fullName: 'English (US)' },
        { id: 2054, name: LanguageType.FrenchCanadian, fullName: 'French (Canada)' },
      ];
    }
    const lang = (locale as LanguageType) || LanguageType.English;
    const selectedLang = Globalization.fromData(languages, locale as LanguageType);
    i18n.changeLanguage(lang);
    setGlobalization(selectedLang);
    setActiveTheme(selectedTheme);
    setAccount(accountData);
    /** this is a hack
     * Setting additional data in local storage only for embedders
     * Using this in "use-fetch-and-set-data.ts" to load the  fallback symbol.
     */
    window.localStorage.setItem(
      'additionalData',
      JSON.stringify({
        fallbackRanksSymbol: configuration.additionalData?.fallbackRanksSymbol,
        shouldFallBack: configuration.isEmbeddingPlatform,
      }),
    );
  };

  const registerCrossWindowListener = () => {
    const isLocalhost = hostOrigin === 'http://localhost:3000';
    window.addEventListener('message', (event: MessageEvent) => {
      //hostToValidateEventsFrom => should be host address from the server.
      if (!isLocalhost && event.origin !== hostOrigin) {
        return;
      }
      try {
        const eventData: { event: string; data: any } | undefined = JSON.parse(event.data);
        if (!eventData) {
          setNotifications([{ type: 'error', content: 'Insufficient data' }]);
          return;
        }
        const eventType = eventData.event;
        const data = eventData.data;
        if (eventType === EmbedderEventType.SetSymbol.toString()) {
          processSetSymbol(data);
          return;
        }
        if (eventType === EmbedderEventType.SetTheme.toString()) {
          processSetTheme(data.theme);
          return;
        }
        if (eventType === EmbedderEventType.SetLocale.toString()) {
          //processSetLocale(data.symbol); //TODO: use setGlobalization.
          return;
        }
        if (eventType === EmbedderEventType.SetQuote.toString()) {
          //processSetSymbol(data.symbol); //TODO: need to check.
          return;
        }
        if (eventType === EmbedderEventType.SetCommissions.toString()) {
          //TODO: As per discussion, this is legacy code which is not required to be implemented.
          return;
        }
      } catch (e) {}
    });
  };

  const processSetSymbol = (data: any) => {
    const { symbol, sentiment } = data;
    if (!symbol || symbol.trim() === '') {
      setNotifications([{ type: 'error', content: 'Unknown symbol' }]);
      return;
    }
    (async () => {
      setIsDataLoading(true);
      const isSuccessful = await fetchAndSetData(symbol, undefined, undefined, undefined, allowedSecurities, sentiment);
      if (!isSuccessful) {
        setNotifications([{ type: 'error', content: 'Quote not found' }]);
        setIsDataLoading(false);
        return false;
      }
      if (isSuccessful && sentiment) {
        setEmbedderSentiment(sentiment.trim().toUpperCase());
        setSentiment(sentiment.trim().toUpperCase());
      }
      setIsDataLoading(false);
    })();
  };

  const processSetTheme = (theme: string) => {
    if (!theme || theme.trim() === '') {
      setNotifications([{ type: 'error', content: 'Unknown theme' }]);
      return;
    }
    const themeType = getThemeType(theme);
    setActiveTheme(themeType);
  };

  const processShareId = async () => {
    const shareId = searchParams.get('shareId');
    if (!shareId || shareId.trim() === '') {
      return false;
    }
    if (!shareRequestPattern.test(shareId)) {
      setNotifications([{ type: 'error', content: 'Incorrect shared trade details' }]);
      return false;
    }
    const response = await fetchSharedTradeById(shareId);
    if (!response || response.hasErrors || !response.data) {
      setNotifications([{ type: 'error', content: 'Incorrect shared trade details' }]);
      return false;
    }
    const data = response.data as any;
    const isSuccessful = await fetchAndSetData(data.symbol, undefined, true, shareId);
    if (!isSuccessful) {
      setNotifications([{ type: 'error', content: 'Insufficent data' }]);
      return false;
    }
    setStrategyShareData(data);
    setView(data.combinationType.toLowerCase());
    setSubView('expand');
    return true;
  };

  if (!isReady || !account || !configuration || !configuration.isEmbeddingPlatform) {
    return <PageLoaderWidget />;
  }

  const render = () => {
    if (widgetName === 'embeddedQuotebarHowWidget' || widgetName === 'prostrategies') {
      return <ProStrategiesWidget />;
    }
    if (widgetName === 'pro') {
      return (
        <PageContainerWidget>
          <AppContainer>
            <ReportsContainer title="what.menu.OpenTradeIdeas">
              <TechnicalIdeas />
            </ReportsContainer>
          </AppContainer>
        </PageContainerWidget>
      );
    }
  };

  return <>{render()}</>;
};
