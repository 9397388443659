import { Grid, Tooltip, useTheme } from '@mui/material';
import { IFilter2, cloneDeep } from '@op/shared/src/models';
import ApplicationContext from '@op/shared/src/models/how/application-context';
import { IFilterGroup } from '@op/shared/src/models/what/i-filter-group';
import { selectedSymbolState } from '@op/shared/src/states';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { IconComponent } from '../common';
import LocalizationContext from '../react-i18next/localization-context';
import { OPBoldTypograpghy, OPLightTypograpghy, OPToggleButton, OrangeButton } from '../styled';

export interface Props {
  filter: IFilterGroup;
  onClick: (group: IFilterGroup) => void;
  onlyIcon?: boolean;
}

export const SentimentFilterWidget2: React.FC<Props> = ({ filter, onClick, onlyIcon }: Props) => {
  const theme = useTheme();
  const { t } = React.useContext(LocalizationContext);
  const selectedSymbol = useRecoilValue(selectedSymbolState);

  const onSelect = (f: IFilter2) => {
    const clone = cloneDeep(filter);
    for (const x of clone.filters) {
      if (x.name === f.name) {
        x.selected = !f.selected;
      }
    }
    onClick(clone);
    logActivity(f);
  };

  const onToggle = (f: IFilter2) => {
    const clone = cloneDeep(filter);

    console.log('clone', clone, f);
    for (const x of clone.filters) {
      if (x.name === f.name) {
        x.selected = !f.selected;
      }
    }
    onClick(clone);
    logActivity(f);
  };

  const logActivity = (filter: IFilter2) => {
    ApplicationContext.userActivityHub?.logActivity(
      'sentimentFilter',
      'sentimentFilter',
      filter.value,
      'click',
      `${selectedSymbol}`,
    );
  };

  const renderIcon = (f: IFilter2) => {
    const icon = f.value === 'Bullish' ? 'trendingUp' : f.value === 'Bearish' ? 'trendingDown' : undefined;
    if (!icon) {
      return null;
    }
    return (
      <span style={{ paddingLeft: 4, paddingRight: 4 }}>
        <IconComponent
          name={icon}
          fill={icon === 'trendingUp' ? theme.palette.success.main : theme.palette.error.main}
        />
      </span>
    );
  };

  if (onlyIcon) {
    const filtered = filter.filters.filter((f) => f.name === 'bullish' || f.name === 'bearish');
    return (
      <Grid container justifyContent="space-between">
        {filtered.map((f, index) => (
          <Grid item xs={6} key={index}>
            <Tooltip title={t(f.translationKey)} placement="top" arrow>
              <OrangeButton
                isSelected={f.selected}
                sx={{
                  padding: '13px 10px 13px 10px',
                }}
                onClick={() => onToggle(f)}>
                <IconComponent
                  name={f.title === 'Bullish' ? 'trendingUp' : 'trendingDown'}
                  fill={f.title === 'Bullish' ? theme.palette.success.main : theme.palette.error.main}
                />
              </OrangeButton>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <Grid container alignItems={'center'} justifyContent={'space-between'}>
      <Grid item xs={3}>
        <OPBoldTypograpghy>{t('what.tradeIdeas.ideas.sentiment')}</OPBoldTypograpghy>
      </Grid>
      <Grid item xs={9} container justifyContent={'flex-end'}>
        {filter.filters.map((f) => {
          return (
            <OPToggleButton key={f.name} value={f.value} selected={f.selected} onClick={() => onSelect(f)}>
              {renderIcon(f)}
              <OPLightTypograpghy>{t(f.translationKey)}</OPLightTypograpghy>
            </OPToggleButton>
          );
        })}
      </Grid>
    </Grid>
  );
};
